@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/base/_01_normalize.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/base/_mixins.scss";

@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/global/_fonts.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/global/_global.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/global/_svg.scss";

//@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/_local-fonts.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/base/_01_normalize.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/base/_mixins.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/global/_fonts.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/global/_global.scss";
@import "/Users/andrianromanov/Documents/Poland/casinof1-pl_com/src/scss/global/_svg.scss";

//

body {
  background-color: #202B38;
  overflow-x: hidden;
}

.hero-slider__wrapper {
  border-radius: 10px;
  overflow: hidden;
}

//header

.centered-container {
  width: 100%;
  max-width: 1280px;
  display: block;
  margin: 0 auto;
  padding: 0px 15px;
}

.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

header#nav {
  position: relative;
  z-index: 100;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
}



#logo {
  position: relative;
  z-index: 123;
  padding: 10px;
  color: #6DDB07;
}




header#nav #menuWrapper {
  height: auto;
  display: block;
}

header#nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

header#nav ul > li {
  float: left;
  margin-right: 20px;
  padding: 10px 0;
}

.buttons-wrapp {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    color: #fff;
    text-decoration: none;
    outline: none;
    gap: 10px;
    &:last-of-type {
      border-radius: 4px;
      background: #2A2A2A;
      padding: 15px 35px;
    }

    &:first-of-type {
      border-radius: 4px;
      background: #E10600;
      padding: 15px 60px;
    }
  }
}

header#nav ul > li > a {
  color: #191818;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  line-height: 140%; /* 22.4px */
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}



header#nav .menu-wrapp ul > li > ul {
  position: absolute;
  left: 10px;
  opacity: 0;
  min-width: 200px;
  margin: 0;
  pointer-events: none;
  /* Adding a transition timing on here will cause mouse out bugs after hover! */
}

header#nav .menu-wrapp ul > li > ul > li {
  padding: 0;

  float: left;
  display: block;
  width:100%;
}

header#nav .menu-wrapp ul > li > ul > li > a {
  border-bottom: 0;
  display: block;
  padding: 0 5px;
  color: #fff;
  width: 90%;
  padding: 0 5%;
  text-transform:none;
}

/* Fade = no need for animations - just timing effects... */

.ddFade {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ddFadeFast {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ddFadeSlow {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -ms-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

/* General Animation settings */
header#nav .menu-wrapp ul > li > ul {
  opacity:0;
  display:block;
  perspective:1000px;
  min-width:inherit;
  width:200px;
}

header#nav .menu-wrapp ul > li > ul.dropin li {
  opacity:1;
  display:block;
  width:100%;
  background:#333;
  padding:3px;
  display:block;
  float: left;
}

li:hover ul.hov {
  animation-duration: 0.3s;
  animation-delay: 0.3s;
}

li:hover ul.hov li {
  animation-direction: normal;
  animation-iteration-count:1;
  animation-timing-function : ease-in-out;
  background:#333;
  animation-fill-mode: forwards;
}

/* Drop In */

/* Swing In right */
header#nav .menu-wrapp ul > li > ul.swing {
  transform: translate(-50px,0) rotateY(-90deg);
}
li:hover ul.swing.hov {
  animation-name: swing;
  animation-fill-mode: forwards;
}
@keyframes swing {
  0%   {opacity:0; transform: translate(-50px,0) rotateY(-90deg);}
  100% {opacity:1; transform: translate(0,0) rotateY(0);}
}
@-webkit-keyframes swing {
  0%   {opacity:0; transform: translate(-50px,0) rotateY(-90deg); }
  100% {opacity:1; transform: translate(0,0) rotateY(0);}
}

/* Swing In Left */
header#nav .menu-wrapp ul > li > ul.swingLft {
  transform: translate(50px,0) rotateY(90deg);
}
li:hover ul.swingLft.hov {
  animation-name: swingLft;
  animation-fill-mode: forwards;
}
@keyframes swingLft {
  0%   {opacity:0; transform: translate(50px,0) rotateY(90deg);}
  100% {opacity:1; transform: translate(0,0) rotateY(0);}
}
@-webkit-keyframes swingLft {
  0%   {opacity:0; transform: translate(50px,0) rotateY(-0deg); }
  100% {opacity:1; transform: translate(0,0) rotateY(0);}
}

/* sort all the overing, going on... */
header#nav ul li:hover a {
  color: #e10600;
}
header#nav .menu-wrapp ul > li:hover ul {
  opacity:1;
  top:24px;
  pointer-events: auto;
}
header#nav .menu-wrapp ul > li > ul .ico:hover,
header#nav .menu-wrapp ul > li > ul a:hover { background-color: rgba(0,0,0,0.3); }
header#nav .menu-wrapp ul > li:hover ul { top :45px; }

/* A non image based mobile menu and close button */
#hamburger {
  display: none;
  width: 25px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 26px;
  cursor: pointer;
}

#hamburger > span {
  background: #000;
  display: block;
  width: 100%;
  height: 3px;
  position: relative;
  margin-top: 3px;
  color: #fff;
  -webkit-transition:all .3s ease;
  -moz-transition:all .3s ease;
  -ms-transition:all .3s ease;
  -o-transition:all .3s ease;
  transition:all .3s ease;
}

#close {
  position: fixed;
  top: 26px;
  right: 15px;
  width: 25px;
  height: 25px;
  z-index: 1200;
  display: none;
  cursor: pointer;
}

#close > span, #close > span::after {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: #eee;
  position: absolute;
  left: 50%;
  margin-left: -2px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#close > span::after {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
#close:hover > span,
#close:hover > span::after { background: #ccc; }
#hamburger:hover > span,
#hamburger:hover > span::after,
#hamburger:hover > span::before {
  background: rgba(250,250,250,0.5);
}

/* Sort the responsive menu */


//header


//slider

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-slider {
  padding: 20px 0;
}

//slider

//default content

.default-content {
  padding: 20px 0;
  h1, h2, h3, h4, h5 {
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 70px */
    margin-bottom: 20px;
  }

  h1 {
    font-size: 50px;
  }

  .table_component {
    overflow: auto;
    width: 100%;
    padding: 20px 0 ;
  }

  .table_component table {
    border: 1px solid #D8D8D8;
    height: 100%;
    width: 100%;
    //table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: center;
  }



  .table_component th {
    border: 1px solid #D8D8D8;
    background-color: #212E42;
    color: #ffffff;
    padding: 10px;
  }

  .table_component td {
    border: 1px solid #D8D8D8;
    background-color: #202B38;
    color: #ffffff;
    padding: 10px;
  }

  p {
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    margin-bottom: 15px;
  }

  ul {
    margin-left: 0;
    margin-bottom: 20px;
    li {
      color: #FFF;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 22.4px */
    }
  }
}

//default content

//tabs hover

.def-title {
  color: #FFF;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 42px */
}

.tabs-hover {
  padding: 20px 0;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    &__item {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      margin-bottom: 15px;
      &:hover {
        .hover-block {
          opacity: 1;
        }
      }
      .tab-img {
        width: 100%;
      }


      .hover-block {
        position: absolute;
        top: 0;
        transition: all .2s ease-in-out;
        opacity: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
        a {
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .bottom-name {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 10px 5px;
            background-color: #212e42;
            span {
              color: #fff;
              display: block;
              text-align: center;
              font-family: Roboto, sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 80%;
            }
          }

        }
      }
    }
  }
}

//tabs hover


//image banner

.image-banner {
  padding: 20px 0;
  a {
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
}

//image banner

//FAQ

.accordion {
  width: 100%;
}

.accordion-header,
.accordion-body {

}

.accordion-header {
  padding: 20px 0;
  background: transparent;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.accordion__item {
  border-bottom: 1px solid #D8D8D8;
}

.accordion__item .accordion__item {
  border-bottom: 1px solid #D8D8D8;
}



.accordion-body {
  background: transparent;
  color: #fff;
  display: none;
  padding: 20px 0;
}

.accordion-body__contents {
  padding: 0;
  font-size: 16px;
}

.accordion__item.active:last-child .accordion-header {
  border-radius: 0;
}

.accordion:first-child > .accordion__item > .accordion-header {
  border-bottom: 1px solid transparent;
}

.accordion__item > .accordion-header:after {
  content: "\f3d0";
  font-family: IonIcons;
  font-size: 1.2em;
  float: right;
  position: relative;
  top: -2px;
  transition: .3s all;
  transform: rotate(0deg);
}

.accordion__item.active > .accordion-header:after {
  transform: rotate(-180deg);
}

.accordion__item.active .accordion-header {
  background: transparent;
}

.accordion__item .accordion__item .accordion-header {
  background: transparent;
  color: #fff;
}

.faq-block {
  padding: 30px 0;
}

//FAQ


//Providers

.providers {
  padding: 30px 0;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    &__item {
      margin-bottom: 16px;
      img {
        width: 100%;
      }
    }
  }
}

//Providers

//Footer

.footer {
  padding: 40px 0px 20px;
  background-color: #0A121F;
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    &__foot-logo {
      margin-bottom: 40px;
      a {
        img {

        }
      }
    }

    &__foot-menu {
      ul {
        padding: 0;
        margin-bottom: 40px;
        li {
          list-style: none;
          margin-bottom: 20px;
          &:last-of-type {
            margin-bottom: 0;
          }
          a {
            color: #606C80;
            text-decoration: none;
            outline: none;
            font-family: 'Roboto', sans-serif;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 26.6px */
            transition: all .2s ease-in-out;
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    &__foot-partners {
      display: flex;
      flex-wrap: wrap;
      &__item {
        position: relative;
        width: 20%;
        padding: 20px;
        img {
          width: 100%;
        }
      }
    }
  }

  &__copyright {
    padding: 20px 0 0;
    border-top: 1px solid #555;
    span {
      display: block;
      text-align: center;
      color: #606C80;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
      text-transform: capitalize;
    }
  }
}

//Footer


//AMEDIA

@media only screen and (max-width: 1000px) {

  .accordion {
    width: 100%;
  }

  .tabs-hover__wrapper__item .hover-block a img {
    max-width: 55px;
  }

  .default-content h1 {
    font-size: 30px;
  }

  #hamburger {
    display: block;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  #close {
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    transform: translateX(50px);
    display: block;
  }

  .buttons-wrapp {
    display: none;
  }

  header#nav {
    position: relative;

    z-index: 1005;
    height: auto;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  header#nav ul {
    display: none;
    opacity: 0;
  }

  header#nav ul > li {
    float: none;
    border-bottom: 1px solid #555;
    display: block;
    padding: 0;
  }

  header#nav ul > li:last-child {
    border-bottom: none !important;
  }

  header#nav ul > li:last-child {
    border-bottom: none;
  }

  header#nav ul > li > a {
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  header#nav .menu-wrapp ul > li > ul {
    top: 0;
    padding: 2px 0;
  }

  header#nav.showNav {
    background-color: #333;
    height: calc(100%);
  }

  header#nav.showNav #menuWrapper {
    height: 300px;
    overflow-y: auto;
    margin-top: 30px;
  }

  header .header-wrapper {
    //padding-top: 5px;
  }

  header#nav .header-wrapper {
    height: auto;
  }

  header#nav.showNav .header-wrapper {
    height: auto;
  }

  header#nav.showNav .menu-wrapp {
    height: auto;
    float: left;
    display: block;
    width: 100%;
  }

  header#nav.showNav ul {
    opacity: 1;
    display: block;
    position: static;
    padding: 0 5%;
    width: 100%;
    font-size: 0.8em;
  }

  header#nav.showNav #hamburger {
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
    transform: translateX(50px);
  }

  header#nav.showNav #close {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    position: absolute;
  }

  header#nav ul:first-child {
    width: 100%;
    padding: 0;
    float: none;
  }

  header#nav ul li {
    display: block;
    clear: both;
    overflow: hidden;
    width:100%;
    padding: 10px 0;
  }

  header#nav .menu-wrapp ul > li > ul {
    position: static;
    opacity: 1;
    pointer-events: auto;
    display: block;
    width:100%;
    float:left;
    margin:0;
    padding:0;
  }

  /* reset all the sub nav animations and styles! */
  header#nav .menu-wrapp ul > li > ul > li, header#nav .menu-wrapp ul > li > ul {
    border: none !important;
    opacity:1 !important;
    display:block !important;
    transform: translate(0,0) rotate(0) !important;
    -webkit-animation: initial !important;
    animation-play: initial !important;
  }

  header#nav .menu-wrapp ul > li > ul > li:hover,
  header#nav .menu-wrapp ul > li:hover,
  header#nav .menu-wrapp ul > li:hover a,
  header#nav .menu-wrapp ul > li > ul .ico:hover {
    background-color: transparent;
    border-bottom: none;
    color: #fff;
  }

  header#nav ul > li > a:hover {
    background-color: transparent;
    border-bottom: none;
  }

}

@media screen and (max-width: 480px) {
  .footer__wrapper__foot-partners__item {
    position: relative;
    width: 25%;
    padding: 10px;
  }
}